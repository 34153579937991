<script setup lang="ts">
import PencilIcon from '../Icons/PencilIcon.vue';
import MagnifyingGlassIcon from '../Icons/MagnifyingGlassIcon.vue';

export interface Props {
    isDeleteAction?: Boolean;
    isShowAction?: Boolean;
    isEditAction?: Boolean;
    isAddAction?: Boolean;
    showLink?: string;
    editLink?: string;
    width?: string;
};

const props = withDefaults(defineProps<Props>(), {
    isDeleteAction:() => false,
    isShowAction:() => false,
    isEditAction:() => false,
    isAddAction:() => false,
    width: "fit-content"
});

defineEmits(["delete", "show", "edit", "add"]);


</script>

<template>
   <td class="itemActionTable">
         
        <button v-if="isShowAction && !showLink" @click="$emit('show')" title="Voir">
            <MagnifyingGlassIcon/>

        </button>  
        <NuxtLink v-else-if="isShowAction && showLink" :to="showLink" title="Voir">
            <MagnifyingGlassIcon/>
        </NuxtLink> 
        <button v-if="isEditAction && !editLink" @click="$emit('edit')" title="Éditer">
            <PencilIcon/>
        </button>
        <NuxtLink v-else-if="isEditAction && editLink" :to="editLink" title="Éditer">
            <PencilIcon/>
        </NuxtLink>    
        <button v-if="isDeleteAction" @click="$emit('delete')" title="Supprimer">
            <svg><use href="../../assets/img/sprite.svg#trash-can"/></svg>
        </button>   
        <button v-if="isAddAction" @click="$emit('add')" title="Ajouter">
            <svg><use href="../../assets/img/sprite.svg#regular-plus"/></svg>
        </button>
    </td>
</template>

<style>
.itemActionTable {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  width: v-bind("props.width");
  text-align: center;
}
.itemActionTable button, .itemActionTable a {
  text-transform: none;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
}
.itemActionTable button:not(:last-child), .itemActionTable a:not(:last-child) {
  margin-right: 0.5rem;
}
.itemActionTable svg {
  width: 20px;
  height: 20px;
}
</style>